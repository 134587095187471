import React from 'react';
import { useState } from 'react';
import points from './points.mp3';

const MyComponent = () => {
    const audioRef = React.useRef();
    const [isRotated, setIsRotated] = useState(false);
    const handleClick = () => {
        audioRef.current.play();
        setIsRotated(!isRotated);
        
    };

    return (
        <div class="container" >
            <img src="images/Bath_time.png" alt="dojo points" onClick={handleClick} style={{transform: isRotated ? 'rotate(360deg)' : 'none' }} />
            <img src="images/Being Patient.png" alt="dojo points" onClick={handleClick} style={{transform: isRotated ? 'rotate(360deg)' : 'none' }} />
            <img src="images/Brush teeth.png" alt="dojo points" onClick={handleClick} style={{transform: isRotated ? 'rotate(360deg)' : 'none' }} />
            <img src="images/Chores done.png" alt="dojo points" onClick={handleClick} style={{transform: isRotated ? 'rotate(360deg)' : 'none' }} />
            <img src="images/Cleaning.png" alt="dojo points" onClick={handleClick} style={{transform: isRotated ? 'rotate(360deg)' : 'none' }} />
            <img src="images/Exercising.png" alt="dojo points" onClick={handleClick} style={{transform: isRotated ? 'rotate(360deg)' : 'none' }} />
            <img src="images/Family time.png" alt="dojo points" onClick={handleClick} style={{transform: isRotated ? 'rotate(360deg)' : 'none' }} />
            <img src="images/Helping.png" alt="dojo points" onClick={handleClick} style={{transform: isRotated ? 'rotate(360deg)' : 'none' }} />
            <img src="images/Homework.png" alt="dojo points" onClick={handleClick} style={{transform: isRotated ? 'rotate(360deg)' : 'none' }} />
            <img src="images/Journaling.png" alt="dojo points" onClick={handleClick} style={{transform: isRotated ? 'rotate(360deg)' : 'none' }} />
            <img src="images/Learning time.png" alt="dojo points" onClick={handleClick} style={{transform: isRotated ? 'rotate(360deg)' : 'none' }} />
            <img src="images/Listening.png" alt="dojo points" onClick={handleClick} style={{transform: isRotated ? 'rotate(360deg)' : 'none' }} />
            <img src="images/Meal time.png" alt="dojo points" onClick={handleClick} style={{transform: isRotated ? 'rotate(360deg)' : 'none' }} />
            <img src="images/Out of bed.png" alt="dojo points" onClick={handleClick} style={{transform: isRotated ? 'rotate(360deg)' : 'none' }} />
            <img src="images/Outside time.png" alt="dojo points" onClick={handleClick} style={{transform: isRotated ? 'rotate(360deg)' : 'none' }} />
            <img src="images/Quiet time.png" alt="dojo points" onClick={handleClick} style={{transform: isRotated ? 'rotate(360deg)' : 'none' }} />
            <img src="images/Reading.png" alt="dojo points" onClick={handleClick} style={{transform: isRotated ? 'rotate(360deg)' : 'none' }} />
            <img src="images/Ready for bed.png" alt="dojo points" onClick={handleClick} style={{transform: isRotated ? 'rotate(360deg)' : 'none' }} />
            <img src="images/Ready for school.png" alt="dojo points" onClick={handleClick} style={{transform: isRotated ? 'rotate(360deg)' : 'none' }} />
            <img src="images/School.png" alt="dojo points" onClick={handleClick} style={{transform: isRotated ? 'rotate(360deg)' : 'none' }} />
            <audio ref={audioRef}>
                <source src={points} type="audio/mpeg" />
            </audio>

        </div>
    );
};

export default MyComponent;

