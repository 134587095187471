import logo from './dojologo.png';
import './App.css';
import MyComponent from './Dojo';

function App() {
  return (
    <div className="App">
    <img src={logo} className="App-logo" alt="logo" />
      <header className="App-header">
      <MyComponent  />
      </header>
    </div>
  );
}

export default App;
